var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fixed-top bg-blue-500 h-10 d-flex align-items-center"},[_c('b-container',{attrs:{"fluid":"lg"}},[_c('b-row',[_c('b-col',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex ml-auto"},_vm._l((_vm.social),function(s,idx){return _c('div',{key:idx},[(s.url)?_c('a',{staticClass:"text-white ml-2 px-1 transition-fast",attrs:{"href":s.url,"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', s.platform]}})],1):_vm._e()])}),0)])],1)],1)],1),_c('transition',{attrs:{"name":"slide-top"}},[_c('b-navbar',{key:_vm.fixed,staticClass:"\n        px-6\n        top-10\n        w-100\n        py-4 py-lg-0\n        align-items-start align-items-lg-center\n        transition-fast\n        bg-white\n      ",class:[
        _vm.fixed ? 'position-fixed' : 'position-absolute',
        _vm.navIsOpen ? 'h-100 flex-column' : 'h-18',
        _vm.fixed || _vm.navIsOpen ? 'z-3' : 'z-1'
      ],attrs:{"toggleable":"lg","type":"light"}},[_c('b-container',{staticClass:"h-100",attrs:{"fluid":"lg"}},[_c('b-navbar-brand',{staticClass:"d-none d-lg-block py-0",attrs:{"to":"/"}},[_c('img',{staticClass:"w-40",attrs:{"src":require("@/assets/img/logo/true360-color.png"),"alt":"True360"}})]),_c('div',{staticClass:"h-100 w-100"},[_c('div',{staticClass:"d-flex justify-space-between flex-row"},[_c('b-navbar-brand',{staticClass:"d-lg-none",attrs:{"to":"/"}},[_c('img',{staticClass:"w-40",attrs:{"src":require("@/assets/img/logo/true360-color.png"),"alt":"True360"}})]),_c('b-navbar-toggle',{staticClass:"\n                ml-auto\n                border-0\n                text-black\n                p-0\n                btn btn-outline-white\n                bg-transparent\n              ",attrs:{"target":"nav-collapse"},on:{"click":function($event){_vm.navIsOpen = !_vm.navIsOpen}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var expanded = ref.expanded;
return [_c('div',{staticClass:"hamburger hamburger--squeeze",class:{ 'is-active': expanded }},[_c('div',{staticClass:"hamburger-box"},[_c('div',{staticClass:"hamburger-inner bg-black"})])])]}}])})],1),_c('b-collapse',{staticClass:"\n              z-3\n              position-relative\n              align-items-start align-items-lg-center\n              mt-lg-0\n              transition-fast\n              h-100\n              d-flex\n              align-items-center\n              justify-content-center\n            ",attrs:{"id":"nav-collapse","is-nav":""}},[(_vm.isLandingPage())?_c('b-navbar-nav',{staticClass:"z-1 w-100"},[_c('div',{staticClass:"d-none d-lg-flex ml-auto"},[_c('b-nav-item',{staticClass:"h-18 d-flex align-items-center",attrs:{"to":"/request"}},[_c('span',{staticClass:"btn btn-orange-500 font-weight-bold"},[_vm._v("Request Now")])])],1),_c('div',{staticClass:"d-lg-none text-center text-xl text-lg-2xl mb-18"},[_c('transition',{attrs:{"name":"slide-bottom","before-enter":_vm.getTransitionDelay(1),"before-leave":_vm.removeTransitionDelay(1)}},[(_vm.navIsOpen)?_c('b-nav-item',{ref:"1",staticClass:"p-3",attrs:{"to":"/request","link-classes":[
                      'p-0',
                      _vm.$route.path === '/request'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Request Now")])]):_vm._e()],1)],1)]):_c('b-navbar-nav',{staticClass:"z-1 w-100"},[_c('div',{staticClass:"d-none d-lg-flex ml-auto"},[_c('b-nav-item',{staticClass:"h-18 d-flex align-items-center",attrs:{"to":"/dealers","link-classes":[
                    'p-0',
                    _vm.$route.path === '/dealers'
                      ? 'text-orange-500'
                      : 'text-black'
                  ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("For Dealers")])]),_c('b-nav-item',{staticClass:"ml-lg-8 mt-4 mt-lg-0 h-18 d-flex align-items-center",attrs:{"to":"/commercial","link-classes":[
                    'p-0',
                    _vm.$route.path === '/commercial'
                      ? 'text-orange-500'
                      : 'text-black'
                  ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("For Commercial")])]),_c('b-nav-item',{staticClass:"ml-lg-8 mt-4 mt-lg-0 h-18 d-flex align-items-center",attrs:{"to":"/pay","link-classes":[
                    'p-0',
                    _vm.$route.path === '/pay' ? 'text-orange-500' : 'text-black'
                  ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Payment")])])],1),_c('div',{staticClass:"d-lg-none text-center text-xl text-lg-2xl mb-18"},[_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.navIsOpen)?_c('b-nav-item',{ref:"1",staticClass:"p-3",attrs:{"to":"/dealers","link-classes":[
                      'p-0',
                      _vm.$route.path === '/dealers'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("For Dealers")])]):_vm._e()],1),_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.navIsOpen)?_c('b-nav-item',{ref:"2",staticClass:"p-3",attrs:{"to":"/commercial","link-classes":[
                      'p-0',
                      _vm.$route.path === '/commercial'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("For Commercial")])]):_vm._e()],1),_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.navIsOpen)?_c('b-nav-item',{ref:"3",staticClass:"p-3",attrs:{"to":"/pay","link-classes":[
                      'p-0',
                      _vm.$route.path === '/pay'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Payment")])]):_vm._e()],1)],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }