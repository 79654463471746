<template>
  <div>
    <div class="fixed-top bg-blue-500 h-10 d-flex align-items-center">
      <b-container fluid="lg">
        <b-row>
          <b-col class="d-flex">
            <div class="d-flex ml-auto">
              <div v-for="(s, idx) in social" :key="idx">
                <a
                  v-if="s.url"
                  class="text-white ml-2 px-1 transition-fast"
                  :href="s.url"
                  target="_blank"
                >
                  <font-awesome-icon :icon="['fab', s.platform]" />
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <transition name="slide-top">
      <b-navbar
        :key="fixed"
        toggleable="lg"
        type="light"
        class="
          px-6
          top-10
          w-100
          py-4 py-lg-0
          align-items-start align-items-lg-center
          transition-fast
          bg-white
        "
        :class="[
          fixed ? 'position-fixed' : 'position-absolute',
          navIsOpen ? 'h-100 flex-column' : 'h-18',
          fixed || navIsOpen ? 'z-3' : 'z-1'
        ]"
      >
        <b-container fluid="lg" class="h-100">
          <b-navbar-brand class="d-none d-lg-block py-0" to="/">
            <img
              class="w-40"
              src="@/assets/img/logo/true360-color.png"
              alt="True360"
            />
          </b-navbar-brand>
          <div class="h-100 w-100">
            <div class="d-flex justify-space-between flex-row">
              <b-navbar-brand class="d-lg-none" to="/">
                <img
                  class="w-40"
                  src="@/assets/img/logo/true360-color.png"
                  alt="True360"
                />
              </b-navbar-brand>
              <b-navbar-toggle
                target="nav-collapse"
                class="
                  ml-auto
                  border-0
                  text-black
                  p-0
                  btn btn-outline-white
                  bg-transparent
                "
                @click="navIsOpen = !navIsOpen"
              >
                <template #default="{ expanded }">
                  <div
                    class="hamburger hamburger--squeeze"
                    :class="{ 'is-active': expanded }"
                  >
                    <div class="hamburger-box">
                      <div class="hamburger-inner bg-black"></div>
                    </div>
                  </div>
                </template>
              </b-navbar-toggle>
            </div>
            <b-collapse
              id="nav-collapse"
              is-nav
              class="
                z-3
                position-relative
                align-items-start align-items-lg-center
                mt-lg-0
                transition-fast
                h-100
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <b-navbar-nav class="z-1 w-100" v-if="isLandingPage()">
                <div class="d-none d-lg-flex ml-auto">
                  <b-nav-item
                    class="h-18 d-flex align-items-center"
                    to="/request"
                  >
                    <span class="btn btn-orange-500 font-weight-bold"
                      >Request Now</span
                    >
                  </b-nav-item>
                </div>
                <div class="d-lg-none text-center text-xl text-lg-2xl mb-18">
                  <transition
                    name="slide-bottom"
                    :before-enter="getTransitionDelay(1)"
                    :before-leave="removeTransitionDelay(1)"
                  >
                    <b-nav-item
                      ref="1"
                      v-if="navIsOpen"
                      class="p-3"
                      to="/request"
                      :link-classes="[
                        'p-0',
                        $route.path === '/request'
                          ? 'text-orange-500'
                          : 'text-black'
                      ]"
                    >
                      <span class="font-weight-bold">Request Now</span>
                    </b-nav-item>
                  </transition>
                </div>
              </b-navbar-nav>
              <b-navbar-nav class="z-1 w-100" v-else>
                <div class="d-none d-lg-flex ml-auto">
                  <b-nav-item
                    class="h-18 d-flex align-items-center"
                    to="/dealers"
                    :link-classes="[
                      'p-0',
                      $route.path === '/dealers'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]"
                  >
                    <span class="font-weight-bold">For Dealers</span>
                  </b-nav-item>
                  <b-nav-item
                    class="ml-lg-8 mt-4 mt-lg-0 h-18 d-flex align-items-center"
                    to="/commercial"
                    :link-classes="[
                      'p-0',
                      $route.path === '/commercial'
                        ? 'text-orange-500'
                        : 'text-black'
                    ]"
                  >
                    <span class="font-weight-bold">For Commercial</span>
                  </b-nav-item>
                  <b-nav-item
                    class="ml-lg-8 mt-4 mt-lg-0 h-18 d-flex align-items-center"
                    to="/pay"
                    :link-classes="[
                      'p-0',
                      $route.path === '/pay' ? 'text-orange-500' : 'text-black'
                    ]"
                  >
                    <span class="font-weight-bold">Payment</span>
                  </b-nav-item>
                </div>
                <div class="d-lg-none text-center text-xl text-lg-2xl mb-18">
                  <transition name="slide-bottom">
                    <b-nav-item
                      ref="1"
                      v-if="navIsOpen"
                      class="p-3"
                      to="/dealers"
                      :link-classes="[
                        'p-0',
                        $route.path === '/dealers'
                          ? 'text-orange-500'
                          : 'text-black'
                      ]"
                    >
                      <span class="font-weight-bold">For Dealers</span>
                    </b-nav-item>
                  </transition>
                  <transition name="slide-bottom">
                    <b-nav-item
                      ref="2"
                      v-if="navIsOpen"
                      class="p-3"
                      to="/commercial"
                      :link-classes="[
                        'p-0',
                        $route.path === '/commercial'
                          ? 'text-orange-500'
                          : 'text-black'
                      ]"
                    >
                      <span class="font-weight-bold">For Commercial</span>
                    </b-nav-item>
                  </transition>
                  <transition name="slide-bottom">
                    <b-nav-item
                      ref="3"
                      v-if="navIsOpen"
                      class="p-3"
                      to="/pay"
                      :link-classes="[
                        'p-0',
                        $route.path === '/pay'
                          ? 'text-orange-500'
                          : 'text-black'
                      ]"
                    >
                      <span class="font-weight-bold">Payment</span>
                    </b-nav-item>
                  </transition>
                </div>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-container>
      </b-navbar>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    fixed: Boolean
  },
  watch: {
    navIsOpen() {
      document.body.classList.toggle("overflow-hidden");
    },
    "$route.path"() {
      this.navIsOpen = false;
    }
  },
  data() {
    return {
      navIsOpen: false,
      social: [
        { platform: "facebook", url: "https://www.facebook.com/acvauctions/" },
        {
          platform: "linkedin",
          url: "https://www.linkedin.com/company/acv-auctions/"
        },
        { platform: "twitter", url: "https://twitter.com/ACVAuctions" },
        {
          platform: "instagram",
          url: "https://www.instagram.com/acvauctions/"
        },
        { platform: "youtube", url: "" }
      ]
    };
  },
  methods: {
    getTransitionDelay(id) {
      this.$nextTick(() => {
        const el = this.$refs[id];
        if (el) {
          el.style.transitionDelay = `${0.2 + 0.05 * id}s`;
        }
      });
    },
    removeTransitionDelay(id) {
      const el = this.$refs[id];
      if (el) {
        el.style.transitionDelay = "0s";
      }
    },
    isLandingPage() {
      console.log(this.$router.history.current["path"]);
      return this.$router.history.current["path"] == "/bmw-minnetonka";
    }
  },
  created() {
    // this.isLandingPage();
  }
};
</script>

<style lang="scss" scoped>
.transition-fast {
  transition: all 0.3s ease;
}

.slide-top-enter-active,
.slide-top-leave-active,
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  @extend .transition-fast;
}

.slide-top-enter,
.slide-top-leave-to {
  transform: translateY(-80px);
  opacity: 0;
}

.slide-bottom-enter {
  opacity: 0;
  transform: translateY(80px);
}
.slide-bottom-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
