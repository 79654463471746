<template>
  <footer>
    <div class="bg-orange-500 py-12">
      <b-container>
        <b-row>
          <b-col cols="12" lg="3" class="text-center text-lg-left mb-4 mb-lg-0">
            <router-link to="/" class="d-block py-3">
              <span class="sr-only">Home</span>
              <img
                class="w-48"
                src="@/assets/img/logo/true360-white.png"
                alt="True360"
              />
            </router-link>
          </b-col>
          <b-col cols="12" lg="3">
            <b-navbar-nav
              class="text-lg text-center text-lg-left mb-12 mb-lg-0"
            >
              <b-nav-item to="/dealers" link-classes="text-white">
                For Dealers
              </b-nav-item>
              <b-nav-item to="/commercial" link-classes="text-white">
                For Commercial
              </b-nav-item>
            </b-navbar-nav>
          </b-col>
          <b-col cols="12" lg="3">
            <b-navbar-nav class="text-lg text-center text-lg-left">
              <b-nav-item
                href="https://www.acvauctions.com/"
                target="blank"
                link-classes="text-white"
              >
                <span>ACV Auctions</span>
                <font-awesome-icon
                  class="ml-4"
                  size="xs"
                  icon="external-link-alt"
                />
              </b-nav-item>
              <b-nav-item
                href="https://www.acvauto.com/acv-capital"
                target="blank"
                link-classes="text-white"
              >
                <span>ACV Capital</span>
                <font-awesome-icon
                  class="ml-4"
                  size="xs"
                  icon="external-link-alt"
                />
              </b-nav-item>
              <b-nav-item
                href="https://acvauto.com/acv-transportation"
                target="blank"
                link-classes="text-white"
              >
                <span>ACV Transportation</span>
                <font-awesome-icon
                  class="ml-4"
                  size="xs"
                  icon="external-link-alt"
                />
              </b-nav-item>
            </b-navbar-nav>
          </b-col>
          <b-col cols="12" lg="3">
            <b-navbar-nav
              class="text-lg text-center text-lg-left mb-12 mb-lg-0"
            >
              <b-nav-item to="/about" link-classes="text-white">
                <span>About</span>
              </b-nav-item>
              <b-nav-item to="/privacy" link-classes="text-white">
                <span>Privacy Policy</span>
              </b-nav-item>
              <b-nav-item to="/terms" link-classes="text-white">
                <span>Terms &amp; Conditions</span>
              </b-nav-item>
            </b-navbar-nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-white py-6 bg-blue-500">
      <b-container>
        <b-row>
          <b-col cols="12" lg="4" order-lg="3">
            <div class="text-center text-lg-right mb-4 mb-lg-0">
              <span>1 (888) 838-9466</span>
            </div>
          </b-col>
          <b-col cols="12" lg="4" order-lg="2">
            <div class="text-center mb-4 mb-lg-0">
              <span>640 Ellicott St. #321 Buffalo, NY 14203</span>
            </div>
          </b-col>
          <b-col cols="12" lg="4" order-lg="1">
            <div class="text-center text-lg-left">
              <span
                >© ACV {{ new Date().getFullYear() }}. All Rights Reserved.
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </footer>
</template>

<script>
/* eslint-disable */
export default {
  name: "Footer",
};
</script>
