import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/dealers",
    name: "Dealers",
    component: () => import("../views/Dealers.vue")
  },
  {
    path: "/commercial",
    name: "Commercial",
    component: () => import("../views/Commercial.vue")
  },
  {
    path: "/consumers",
    name: "Consumers",
    component: () => import("../views/Consumers.vue")
  },
  {
    path: "/learn-more",
    name: "LearnMore",
    component: () => import("../views/LearnMore.vue")
  },
  {
    path: "/startnow",
    component: () => import("../views/LearnMore.vue")
  },
  {
    path: "/pay",
    name: "Pay",
    component: () => import("../views/Pay.vue")
  },
  {
    path: "/request",
    beforeEnter() {
      location.href = "https://buy.stripe.com/9AQdTe2mt7teb84144";
    }
  },
  {
    path: "/getstarted",
    component: () => import("../views/LearnMore.vue")
  },
  {
    path: "/privacy",
    component: () => import("../views/Privacy.vue")
  },
  {
    path: "/terms",
    component: () => import("../views/Terms.vue")
  },
  {
    path: "/about",
    component: () => import("../views/About.vue")
  },
  {
    path: "/bmw-minnetonka",
    component: () => import("../views/Minnetonka.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
